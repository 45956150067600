import { useQuasarPlugin } from '@mfl/common-components';
import { bootstrapMicrofrontend } from '@mfl/vue-common';

import mfe from './employee-portal-signatures-mfe.vue';

const { bootstrap, mount, unmount } = bootstrapMicrofrontend({
  name: 'employee-portal-signatures',
  mainComponent: mfe,
  targetElement: '#content',
  includeCss: ['/employee-portal-signatures/employee-portal-signatures.css'],
  handleVueApp: (app) => {
    useQuasarPlugin(app);
  },
});

export { bootstrap, mount, unmount };
